<template>
    <v-card class="pa-3">
        <v-card-title>
            {{ $t('tutorial.walletHowToTitle') }} {{ getWalletString }}
        </v-card-title>
        <v-card-subtitle>
            <p
                v-html="$t('tutorial.walletHowToDescription', { 'wallet': getWalletString, 'steps': getSteps.length })">
            </p>
            <strong>{{ $t('tutorial.walletHowToDescriptionAllBlockchain') }}</strong>
        </v-card-subtitle>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-carousel hide-delimiters v-if="!$SUPPORTED_BLOCKCHAINS.includes(blockchain)">
                        <v-carousel-item v-for="(step, index) in getSteps" :key="index">
                            <v-card elevation="0">
                                <v-card-title>
                                    <h3>STEP {{ index + 1 }}</h3>
                                </v-card-title>
                                <v-card-text>
                                    <v-row no-gutters>
                                        <v-col>
                                            <p v-html="step.description"></p>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="step.image" no-gutters>
                                        <v-col>
                                            <v-img :src="step.image" contain @click="openDialog"
                                                class="clickable-image"></v-img>
                                            <v-dialog v-model="dialogVisible" width="100%">
                                                <v-card>
                                                    <v-img :src="step.image"></v-img>
                                                </v-card>
                                            </v-dialog>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-carousel-item>
                    </v-carousel>
                    <ol v-else>
                        <li v-for="(step, index) in getSteps" :key="index">
                            <p v-html="step.description"></p>
                        </li>
                    </ol>
                    
                </v-col>
            </v-row>
            <!--
            <v-row>
                <v-col>
                    <v-alert color="#7289DA" elevation="2" colored-border type="info" :border="'left'" dense
                        @click="goToDiscord">
                        <p v-html="$t('tutorial.helpDiscord')"></p>
                        <template v-slot:prepend>
                            <v-icon color="#7289DA" class="fa-brands fa-discord mr-5"></v-icon>
                        </template>
                    </v-alert>
                </v-col>
            </v-row>
            -->
            <v-row>
                <v-col>
                    <v-alert color="accent" elevation="2" colored-border type="info" :border="'left'" dense
                        @click="sendEmail">
                        <p v-html="$t('tutorial.helpEmail', { 'subject': getSubject })"></p>
                        <template v-slot:prepend>
                            <v-icon color="accent" class="ml-1 mr-5">mdi-email</v-icon>
                        </template>
                    </v-alert>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
  
<script>
export default {
    name: "wallet-tutorial-carousel",
    props: {
        blockchain: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dialogVisible: false,
        }
    },
    computed: {
        getSubject() {
            return encodeURIComponent(`${this.$t('tutorial.supportRequest')} ${this.$options.filters.BlockchainTypeFormatter(this.blockchain)}`)
        },
        getWalletString(){
            if (this.$SUPPORTED_BLOCKCHAINS.includes(this.blockchain)){
                return this.$options.filters.BlockchainTypeFormatter(this.blockchain)
            } else {
                return this.$options.filters.WalletTypeFormatter(this.blockchain)
            }
        },
        getSteps() {
            if (this.blockchain == 'BITCOIN') {
                return [
                    {
                        description: this.$t('tutorial.walletStep1'),
                        image: null
                    },
                    {
                        description: this.$t('tutorial.bitcoinStep2'),
                        image: null
                    },
                    {
                        description: this.$t('tutorial.walletStep3'),
                        image: null
                    },
                    {
                        description: this.$t('tutorial.walletStep4'),
                        image: null
                    },
                ];
            }
            if (this.$SUPPORTED_BLOCKCHAINS.includes(this.blockchain)){
                return [
                    {
                        description: this.$t('tutorial.walletStep1'),
                        image: null
                    },
                    {
                        description: this.$t('tutorial.evmStep2'),
                        image: null
                    },
                    {
                        description: this.$t('tutorial.walletStep3'),
                        image: null
                    },
                    {
                        description: this.$t('tutorial.walletStep4'),
                        image: null
                    },
                ];
            }
            if (this.blockchain == 'METAMASK'){
                return [
                    {
                        description: this.$t('tutorial.metamaskStep1'),
                        image: require('@/assets/tutorial/METAMASK/STEP_1.png')
                    },
                    {
                        description: this.$t('tutorial.evmStep2'),
                        image: require('@/assets/tutorial/METAMASK/STEP_2.png')
                    },
                    {
                        description: this.$t('tutorial.walletStep3'),
                        image: require('@/assets/tutorial/METAMASK/STEP_3.png')
                    },
                    {
                        description: this.$t('tutorial.walletStep4'),
                        image: require('@/assets/tutorial/METAMASK/STEP_4.png')
                    },
                ];
            }
            if (this.blockchain == 'TREZOR'){
                return [
                    {
                        description: this.$t('tutorial.trezorStep1'),
                        image: require('@/assets/tutorial/TREZOR/STEP_1.png')
                    },
                    {
                        description: this.$t('tutorial.trezorStep2'),
                        image: require('@/assets/tutorial/TREZOR/STEP_2.png')
                    },
                    {
                        description: this.$t('tutorial.trezorStep3'),
                        image: require('@/assets/tutorial/TREZOR/STEP_3.png')
                    },
                    {
                        description: this.$t('tutorial.walletStep3'),
                        image: require('@/assets/tutorial/TREZOR/STEP_4.png')
                    },
                    {
                        description: this.$t('tutorial.walletStep4'),
                        image: require('@/assets/tutorial/TREZOR/STEP_5.png')
                    },
                    
                ];
            }
            if (this.blockchain == 'TRUSTWALLET'){
                return [
                    {
                        description: this.$t('tutorial.trustWalletStep1'),
                        image: require('@/assets/tutorial/TRUST_WALLET/STEP_1.png')
                    },
                    {
                        description: this.$t('tutorial.trustWalletStep2'),
                        image: require('@/assets/tutorial/TRUST_WALLET/STEP_2.png')
                    },
                    {
                        description: this.$t('tutorial.trustWalletStep3'),
                        image: require('@/assets/tutorial/TRUST_WALLET/STEP_3.png')
                    },
                    {
                        description: this.$t('tutorial.trustWalletStep4'),
                        image: require('@/assets/tutorial/TRUST_WALLET/STEP_4.png')
                    },
                    {
                        description: this.$t('tutorial.walletStep3'),
                        image: require('@/assets/tutorial/TRUST_WALLET/STEP_5.png')
                    },
                    {
                        description: this.$t('tutorial.walletStep4'),
                        image: require('@/assets/tutorial/TRUST_WALLET/STEP_6.png')
                    },
                    
                ];
            }
            if (this.blockchain == 'LEDGER'){
                return [
                    {
                        description: this.$t('tutorial.ledgerWalletStep1'),
                        image: require('@/assets/tutorial/LEDGER/STEP_1.png')
                    },
                    {
                        description: this.$t('tutorial.ledgerWalletStep2'),
                        image: require('@/assets/tutorial/LEDGER/STEP_2.png')
                    },
                    {
                        description: this.$t('tutorial.ledgerWalletStep3'),
                        image: require('@/assets/tutorial/LEDGER/STEP_3.png')
                    },
                    {
                        description: this.$t('tutorial.ledgerWalletStep4'),
                        image: require('@/assets/tutorial/LEDGER/STEP_4.png')
                    },
                    {
                        description: this.$t('tutorial.walletStep3'),
                        image: require('@/assets/tutorial/LEDGER/STEP_5.png')
                    },
                    {
                        description: this.$t('tutorial.walletStep4'),
                        image: require('@/assets/tutorial/LEDGER/STEP_6.png')
                    },
                    
                ];
            }
            if (this.blockchain == 'LEDGER_BITCOIN'){
                return [
                    {
                        description: this.$t('tutorial.ledgerWalletStep1'),
                        image: require('@/assets/tutorial/LEDGER/STEP_1.png')
                    },
                    {
                        description: this.$t('tutorial.ledgerBitcoinWalletStep2'),
                        image: require('@/assets/tutorial/LEDGER_BITCOIN/STEP_2.png')
                    },
                    {
                        description: this.$t('tutorial.walletStep3'),
                        image: require('@/assets/tutorial/LEDGER/STEP_5.png')
                    },
                    {
                        description: this.$t('tutorial.walletStep4'),
                        image: require('@/assets/tutorial/LEDGER/STEP_6.png')
                    },
                    
                ];
            }
            if (this.blockchain == 'TREZOR_BITCOIN'){
                return [
                    {
                        description: this.$t('tutorial.trezorStep1'),
                        image: require('@/assets/tutorial/TREZOR/STEP_1.png')
                    },
                    {
                        description: this.$t('tutorial.trezorBitcoinStep2'),
                        image: require('@/assets/tutorial/TREZOR_BITCOIN/STEP_2.png')
                    },
                    {
                        description: this.$t('tutorial.trezorBitcoinStep3'),
                        image: require('@/assets/tutorial/TREZOR_BITCOIN/STEP_3.png')
                    },
                    {
                        description: this.$t('tutorial.walletStep3'),
                        image: require('@/assets/tutorial/TREZOR/STEP_4.png')
                    },
                    {
                        description: this.$t('tutorial.walletStep4'),
                        image: require('@/assets/tutorial/TREZOR/STEP_5.png')
                    },
                    
                ];
            }
            return []
        },
    },
    methods: {
        openDialog() {
            if (this.$vuetify.breakpoint.xs) {
                this.dialogVisible = true;
            }
        },
        goToDiscord() {
            window.open("https://discord.gg/W4p4YUDWfV", "_blank");
        },
        sendEmail() {
            var mail = `mailto:support@okipo.io?subject=${this.getSubject}`;
            var a = document.createElement('a');
            a.href = mail;
            a.click();
        }
    },
};
</script>
<style lang="scss" scoped>
.clickable-image {
    cursor: pointer;
}
ol {
  list-style-type: none;
  counter-reset: step-counter;
}

li {
  position: relative;
  margin-bottom: 10px;
  
  &:before {
    position: absolute;
    left: -30px; // Adjust the position of the counter
    top: 50%;
    transform: translateY(-50%);
    width: 20px; // Adjust the circle size as needed
    height: 20px; // Adjust the circle size as needed
    background-color:  var(--v-info-base); // Adjust the circle color as needed
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; // Adjust the text color as needed
    font-weight: bold;
    font-size: 12px; // Adjust the text size as needed
    content: counter(step-counter); // Increment the counter through CSS
    counter-increment: step-counter; // Increment the counter through CSS
  }
}
</style>