<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" sm="6" v-if="$vuetify.breakpoint.xs">
          <wallet-tutorial-carousel :blockchain="selectedUserAddress.blockchainType"></wallet-tutorial-carousel>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-row>
          <v-col :align="'center'">
            <h1>{{ $t('connection.connectPersonalAddressTitle') }} {{ getWalletString }}</h1>
          </v-col>
        </v-row>
        <v-form ref="addPersonalAddressForm" v-model="validForm" lazy-validation class="my-3">
          <v-row no-gutters>
            <v-col>
              <v-text-field v-model="selectedUserAddress.name" :label="$t('label.name')"
            :hint="$t('addAddress.nameFieldHint')" persistent-hint :rules="nameRules" solo>
          </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field required v-model="selectedUserAddress.address" :label="$t('label.publicAddress')"
            :rules="addAddressRules" :hint="$t('addAddress.addressFieldHint')" persistent-hint solo
            >
          </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <add-tag-select></add-tag-select>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="mt-5">
          <v-spacer />
          <v-btn @click="addNewPersonalAddress" :loading="loading" color="accent" class="white--text" tile>
            {{ $t('addAddress.buttonText') }}
          </v-btn>
          <v-spacer />
        </v-row>
        <v-divider v-if="$vuetify.breakpoint.xs" class="mt-10"></v-divider>
      </v-col>
      <v-divider :vertical="!$vuetify.breakpoint.xs" class="my-5">
      </v-divider>
      <v-col cols="12" md="6" sm="6" v-if="!$vuetify.breakpoint.xs">
          <wallet-tutorial-carousel :blockchain="selectedUserAddress.blockchainType"></wallet-tutorial-carousel>
      </v-col>
    </v-row>
    <v-dialog v-model="loading" width="600px" persistent>
      <v-card class="dialog-top-color" height="300px">
        <v-card-text class="text-center align-center justify-center mt-15">
          <v-progress-circular indeterminate size="100" :width="10" color="accent"></v-progress-circular>
          <p class="mt-5">{{ $t('addAddress.circularProgressSentence') }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import AddTagSelect from "../../components/select/AddTagSelect.vue"
import WalletTutorialCarousel from '../../components/carousel/WalletTutorialCarousel.vue'
export default {
  name: 'add-personal-address-page',
  components: { AddTagSelect, WalletTutorialCarousel },
  data() {
    return {
      loading: false,
      validForm: false,
      nameRules: [
        v => !!v || this.$t('addAddress.nameRequiredText')
      ],
      addAddressRules: [
        v => !!v || this.$t('addAddress.addressRequiredText')
      ],
    }
  },
  computed: {
    ...mapFields("selectedUserAddress", ["selectedUserAddress"]),
    ...mapFields("loggedUser", ["loggedUser"]),
    getWalletString(){
            if (this.$SUPPORTED_BLOCKCHAINS.includes(this.selectedUserAddress.blockchainType)){
                return this.$options.filters.BlockchainTypeFormatter(this.selectedUserAddress.blockchainType)
            } else {
                return this.$options.filters.WalletTypeFormatter(this.selectedUserAddress.blockchainType)
            }
        },
  },
  methods: {
    ...mapActions("userAddresses", ["addPersonalUserAddress"]),
    ...mapActions("alert", ["createAlert"]),
    addNewPersonalAddress() {
      if (!this.$refs.addPersonalAddressForm.validate()) {
        return
      }
      this.loading = true
      this.addPersonalUserAddress(this.selectedUserAddress).then(() => {
        this.createAlert({ message: this.$t('message.addPersonalAddressSuccess'), type: "success" })
        this.$router.push("/connection-management")
      }).catch((err) => {
        if (err.response.data && err.response.data.message) {
          if (err.response.data.message == 'ADDRESS_NOT_SUPPORTED') {
            this.createAlert({ message: this.$t('message.addPersonalAddressAddressNotSupportedError'), type: "error" })
          }
          if (err.response.data.message == 'ADDRESS_BLACKLISTED') {
            this.createAlert({ message: this.$t('message.addPersonalAddressAddressBlacklistedError'), type: "error" })
          }
          if (err.response.data.message == 'ADDRESS_ALREADY_PRESENT') {
            this.createAlert({ message: this.$t('message.addPersonalAddressAddressAlreadySavedError'), type: "error" })
          }
          if (err.response.data.message == 'PERSONAL_USER_ADDRESSES_LIMIT_REACHED') {
            this.createAlert({ message: this.$t('message.addPersonalAddressAddressLimitReachedError'), type: "error" })
          }
          if (err.response.data.message == 'MAXIMUM_DIFFERENT_TAGS_REACHED') {
            this.createAlert({ message: this.$t('message.addPersonalAddressTagLimitReachedError'), type: "error" })
          }
        } else {
          this.createAlert({ message: this.$t('message.genericError'), type: "error" })
        }
      }).finally(() => {
        this.loading = false
      });
    },
  },
  created() {
    this.selectedUserAddress = {
      id: null,
      name: null,
      address: null,
      userAddressType: 'PERSONAL',
      tag: null,
    }
    if (this.$route.params.blockchain) {
      this.selectedUserAddress.blockchainType = this.$route.params.blockchain.toUpperCase()
    }
  }
}
</script>

<style>
  .v-progress-linear.animate .v-progress-linear__determinate
{
    animation: move 2s linear infinite;
}
@keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100px 100px;
    }
}

.custom-tooltip {
    opacity: var(--v-tooltip-opacity, 1) !important;
    background: var(--v-tooltip-bg, rgb(109, 109, 109)) !important;
}
</style>